import React from "react"
import Album from "../components/album"

export default (props) => {
  const formatsAvailable = "12\" colored vinyl LP, digital download"
  return (
    <Album
      title="Undress My Mind"
      recordedDate="Mar 2013"
      recordedLocation="The Pound Pad, Jefferson, GA"
      engineers="Will Manning"
      releaseDate="July 2, 2013"
      recordLabel="Shoulder Tap Records"
      catalogNumber="SHT013"
      formatsAvailable={formatsAvailable}
      location={props.location} />
  )
}
